import React from 'react';
import './css/Home.css';

const Home: React.FC = () => {
  return (
    <div className="container">
      <h1 className="title">
        Follow{' '}
        <a
          href="https://www.instagram.com/azzy.tech/"
          target="_blank"
          rel="noopener noreferrer"
          className="instagram-link"
        >
          @azzy.tech
        </a>{' '}
        on Instagram
      </h1>
      <p className="message">Our new site is on the way. Stay tuned!</p>
    </div>
  );
};

export default Home;
