import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Links from './Links';
import './css/App.css';

const App: React.FC = () => {
  return (
    <div>
      <header>
        <div className="header-content">
          <h1>
            <Link to="/">AzzyTech</Link>
          </h1>
          <nav>
            <Link to="/links">Links</Link>
          </nav>
        </div>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/links" element={<Links />} />
        </Routes>
      </main>
      <footer>
        <a href="mailto:contact@azzytech.net" className="email-link">
          <p>contact@azzytech.net</p>
        </a>
      </footer>
    </div>
  );
};

export default App;
