import React from 'react';
import './css/Links.css';

const Links: React.FC = () => {
  return (
    <div className="container">
      <h1 className="title">Quick Links</h1>
      <p className="description">Discover some useful resources:</p>
      <ul className="link-list">
        <li>
          <a href="https://nuphy.com/amonov" target="_blank" rel="noopener noreferrer">
            NuPhy
          </a> - A unique and versatile keyboard known for its thocky sound.
        </li>
      </ul>
    </div>
  );
};

export default Links;
